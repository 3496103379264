<script lang="ts">
  import { DialogContent, activeDialog } from '../state';
  import Dialog from './Dialog.svelte';

  export let content: DialogContent;
  export let className: string | null = null;
  $: show = $activeDialog === content;

  $: {
    if (show) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }
</script>

{#if show}
  <Dialog {className}>
    <div class="flex-cnt rounded-md bg-pearl-200 p-rhythm-2 pb-0"><slot /></div>
  </Dialog>
{/if}
