<script lang="ts">
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import { currentProductPageData } from 'state/stores/nav';
  import { productSkuSelectionById } from 'state/stores/product';
  import { get } from 'svelte/store';
  import { DialogContent, activeDialog } from '../../state';
  import { svelteAddToLocalCart } from 'state/stores/checkout';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { makeQueryPromise } from '@svelte/service/backend/queryStore';
  import { getContextGraphqlWorker } from '@svelte/service/worker/context/context';
  import { makeClassNames } from 'lib/util';

  export let className: string | null = null;

  const queryWorker = getContextGraphqlWorker();
  const appEventDispatcher = getAppEventDispatcher();
  const currentSkuSelection = productSkuSelectionById(
    $currentProductPageData?.id
  );

  async function handleAddToCart() {
    const productData = get(currentProductPageData);
    const selectedSkuId =
      productData && productData.skus.length === 1
        ? productData.skus[0].id
        : get(currentSkuSelection)?.id;

    if (!selectedSkuId) {
      activeDialog.set(DialogContent.SelectProductSku);
      return;
    }

    const allProducts = await makeQueryPromise({
      key: 'allProducts',
      variables: {},
      worker: queryWorker
    });

    svelteAddToLocalCart(
      allProducts,
      $currentProductPageData?.id,
      selectedSkuId,
      appEventDispatcher
    );
  }
</script>

<button
  class={makeClassNames('flex-cnt w-32', className)}
  on:click={handleAddToCart}
>
  <InterfaceText size={TextSize.LongPrimer} bold color=""
    >ADD TO CART</InterfaceText
  >
</button>
