<script lang="ts">
  import { NewsletterPromptNotification } from '@svelte/lib/notifications/types';
  import Dialog from '../../Dialog.svelte';
  import NewsletterPrompt from '@svelte/modules/comms-menu/components/notifications/newsletter-prompt/NewsletterPrompt.svelte';

  export let notification: NewsletterPromptNotification | null = null;
</script>

{#if notification}
  <Dialog className="border-4 border-primary-700">
    <NewsletterPrompt
      on:active
      {notification}
      imageSize="m"
      withoutSuccessCTA
    />
  </Dialog>
{/if}
